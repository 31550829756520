<template>
   <!-- 通过判断numPrice    来决定特价商品样式    SpeProBg.png -->
  <el-col :span="24" class="item" > 
    <!-- <span class="ProductLabel" v-if="promotionMap!=''">
        {{promotionMap}}
    </span> -->
    <span class="ProductLabel" v-if="info.isLimit==1">
       限购商品
    </span>
    <!-- {{ info.isLimit }} -->
    <!-- 满减 moneyOff、满赠 gift、套餐setMeal -->
    <span class="ProductLabel" v-if="info.promotionProductVOS && info.promotionProductVOS.length>0 && info.promotionProductVOS[0].type!='ONE_BUY促销活动' && info.promotionProductVOS[0].type!='特价'">
      {{info.promotionProductVOS.length==1?info.promotionProductVOS[0].type:"促销活动"}}
    </span>
     <span class="ProductLabel" v-if="info.promotionMap && info.promotionMap.length>0 ">
      {{info.promotionMap.length==1?info.promotionMap[0].type:"促销活动"}}  
    </span>
    <div class="wraper" @mouseenter="show()" @mouseleave="hide()">
      <!-- v-if="pics != 1" -->
      <div class="imgWrap"  @click="jumpDetail(info,info.isRecentExpiration)">
        <img v-if="info.quantity<Number(info.minOrderNum)" class="soldOut" alt="补货中" src="@/assets/soldOut.png" />
        <img :alt="info.name | defaultName" :src="info.pic+picSuffix | defaultImage" />

        <!-- 通过判断numPrice      来决定特价商品样式    SpeProBg.png -->
        <img v-if="info.quantity>=Number(info.minOrderNum) && info.numPrice && info.numPrice!=''"  class="SpeProBg" src="@/assets/SpeProBg.png" alt="">
        <div v-if="info.quantity>=Number(info.minOrderNum) && info.numPrice && info.numPrice!=''" class="TopRightBox"  >
           省{{ Number(info.memberPrice-numPriceArr[numPriceArr.length - 1].price).toFixed(2) }}元/{{info.unit}}
        </div>
        <!--  v-if="info.numPrice && info.numPrice!=''" -->
        <div  v-if="info.quantity>=Number(info.minOrderNum) && info.numPrice && info.numPrice!=''"  class="bottomLeftBox"  >
            <span>
              活动价
            </span>
            <!-- <span class="DiscountBox" v-if="numPriceArr[numPriceArr.length - 1].ratio">{{numPriceArr[numPriceArr.length - 1].ratio}}<b>折</b> 起</span> -->
            <span class="SpecialBox">
              ￥{{Number(numPriceArr[numPriceArr.length - 1].price).toFixed(2)}} 起
            </span>
        </div>
        <div v-if="info.quantity>=Number(info.minOrderNum) && info.numPrice && info.numPrice!=''" class="bottomRightBox"  >

          买{{numPriceArr[numPriceArr.length - 1].num}}{{info.unit}}立减{{ (Number(info.memberPrice-numPriceArr[numPriceArr.length - 1].price)*numPriceArr[numPriceArr.length - 1].num).toFixed(2)}}元
        </div>
      </div>

<div class="desBox">
      
    
      <!-- {{ info.partSpecification }} -->
      <h3 class="shopName" :title="info.name">{{ info.name | defaultName }} </h3>
      <div class="specification">
        <!-- <span>规</span>  -->
        {{ info.specification }}    {{ info.partSpecification }}  
      </div>
       <!-- <div class="specification">
        <span>件</span> 
      </div> -->
      <div class="specification">
        <!-- <span>厂</span>  -->
        {{ info.manufacturerName }}
      </div>
       <div class="specification">
         <!-- <span>效</span>  -->
         效期优于{{ info.validDateFromat }}
        </div>
        <div class="hidePrice" v-show="!isqualification">
          资质认证后显示商品价格   
        </div>
        <div class="hidePrice" v-show="isqualification">
          <span class="price SpecialBox"  v-if="info.quantity>=Number(info.minOrderNum) && info.numPrice && info.numPrice!=''" >￥{{Number(numPriceArr[numPriceArr.length - 1].price).toFixed(2)}} 起</span>
          <span class="price"   v-else-if="info.numPrice && info.numPrice!=''" >{{(info.price!=="***"?Number(info.price)>=Number(info.memberPrice)?'':info.memberPrice:'')| capitalize}}</span>
          <span class="price"   v-else >{{ info.price==="***"?"会员可见":info.price  | capitalize }}</span>
          <!-- {{Number(info.price)>=Number(info.memberPrice)}} {{info.memberPrice}} -->
          <del   v-if="info.quantity>=Number(info.minOrderNum) && info.numPrice && info.numPrice!=''" >
            {{(info.price!=="***"?Number(info.price)>Number(info.memberPrice)?'':info.memberPrice:'')| capitalize}}
          </del>
            <del   v-else-if="info.numPrice && info.numPrice!=''" ></del>
            <del   v-else >{{info.price!=="***"?Number(info.price)>=Number(info.memberPrice)?'':info.memberPrice:''}}</del>
            <span class="bottomBox" v-if="info.quantity<1000 &&info.quantity>Number(info.minOrderNum)">剩余{{info.quantity}}{{info.unit}}</span>
          <!-- info.quantity<1 &&  -->
          <span class="bottomBox" v-if="info.quantity < Number(info.minOrderNum) && info.deliveryTimeFormat && info.price!='***'" >
            到货日期:{{info.deliveryTimeFormat}}
          </span>
        </div>
        <div class="validity">
          零售价￥{{ info.price==="***"?"会员可见":info.originalPrice}} | 毛利率{{ info.grossProfitMargin }}%
      </div>
      <div class="tagBox">
          <span  v-if="info.otcType==1 && info.drugCategory==0"  class="tag bgRed titColor ">OTC</span>
          <span  v-if="info.otcType==1 && info.drugCategory==1"  class="tag bgGreen titColor  ">OTC</span>
          <span  v-if="info.otcType==0"  class="tag bgRed titColor ">Rx</span>
          <span  v-if="info.newStatus=='true'||info.newStatus==true"  class="tag bgGreen titColor">新品</span>
          <span  v-if="info.medicalInsuranceType>0"  class="tag bgBlue titColor">{{info.medicalInsuranceType==1?'甲类医保':info.medicalInsuranceType==2?'乙类医保':'医保'}}</span>
          <span  v-if="info.proprietary==1"   class="tag bgOrange titColor">自营</span>
      </div>
       <transition>
          <div class="buyCart" v-if="status"  >
            <el-row>
              <el-col :span="16">
                <div class="counter">
                  <el-input-number
                    v-model="num"
                    @change="handleChange"
                    :min="Number(info.minOrderNum)"
                    :max="isLimitNums"
                    v-if="info.minOrderNum"
                    :step="info.minOrderNum ? Number(info.minOrderNum) : '1'"
                    step-strictly
                  ></el-input-number>
                </div>
              </el-col>
              <!-- <el-col :span="6"
                ><div class="btnImg">
                  <img :src="cartSrc" @click="addCartFun(info)"     v-log="[info.name, '点击加入购物车']"     alt="cartSrc"/></div
              ></el-col> -->
             
               <el-col :span="4"   v-if="Number(StoreType)>1">
                  <div class="btnImg">
                    <img :src="cartSrcs" @click="addCartMoreFun(info)"     v-log="[info.name, '点击加入购物车']"     alt="cartSrc"/>
                   </div>
                </el-col>
                <el-col :span="4"   v-else><div class="btnImg">
                   </div>
                </el-col>
               <el-col :span="4"
                ><div class="btnImg">
                  <img :src="cartSrc" @click="addCartOneFun(info)"     v-log="[info.name, '点击加入购物车']"     alt="cartSrc"/></div
              ></el-col>
            </el-row>
          </div>
        </transition>
    </div>
</div>


    <!-- 多店铺弹窗 -->
    <el-dialog
      id="StoreBox"
      title="勾选店铺"
      :visible.sync="StoreShow"
    >
     <el-col :span="24" class="selectedAllBox">
            <el-col :span="19">
              <label>
                <input
                  type="checkbox"
                  :checked="selectedAll"
                  @change="AllSelectedFun()"
                />
                全选
              </label>
            </el-col>
     </el-col>
      <div class="MembersBox">
        <el-col class="LineBox" :span="24" v-for="(item, index) in StoreList" :key="index" @click="jumpPath(index)">
          <el-col :span="19" >
            <label  @change="handleCheckChange(item)">
              <input
                type="checkbox"
                :checked="item.flag"
              />
              {{item.enterpriseName}}
              <span class="unauthorizedBtn Btnbox" >正常</span>
                <!-- <span class="lockBtn Btnbox" v-if="item.disableStatus==1">锁定</span>
                <span class="certifiedBtn Btnbox" v-else-if="item.auditStatus==4">正常</span>
                <span class="pastBtn Btnbox" v-else-if="item.auditStatus==6">资质过期</span>
                <span class="unauthorizedBtn Btnbox" v-else>未认证</span> -->
            </label>
          </el-col>
            <el-col :span="5" class="counterBox">
              <div class="counter">
                  <InputNumbers :item="info" :StoreItem="item"></InputNumbers> 
              </div>
            </el-col>

        </el-col>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="AddStoreFun(info)">加入购物车</el-button>
      </span>
    </el-dialog>

  </el-col>
</template>
<style class="shopItem">

/* @media screen and (max-width: 1280px) {
  .shopInfo > .item {
    width: 186px !important;
  }
  .goodsList > .item {
    width: 186px !important;
  }
} */
.Btnbox{
  width:60px;
    text-align: center;
    height: 20px;
    padding: 0 5px;
    line-height: 19px;
    
    font-size: 12px;
    border-style: solid;
    box-sizing: border-box;
    white-space: nowrap;
    display: inline-block;
}
.lockBtn{
  background-color: #999999;
    border-color: #999999;
    border-width: 1px;
    color: #fff;
}
.pastBtn{
  background-color: #8f8e92;
    border-color: #8f8e92;
    border-width: 1px;
    color: #fff;
}
.unauthorizedBtn{
   background-color: #ff6368;
    border-color: #ff6368;
    border-width: 1px;
    color: #fff;
}
.buyCart {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 54px;
  /* width: 228px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 19px;
  padding-right: 19px;
  box-sizing: border-box;
  z-index: 100px;
}
.buyCart .el-row {
  width: 100%;
}

.counter .el-input-number {
  width: 108px;
  height: 34px;
  line-height: 34px;
  color: #979797 !important;
}
.counter .el-input-number .el-input-number__decrease {
  width: 23.5px !important;
  display: inline-block;
  height: 32px;
  border-radius: 0px;
}
.counter .el-input-number .el-input-number__increase {
  width: 23.5px !important;
  display: inline-block;
  height: 32px;
  border-radius: 0px;
}
.counter .el-input-number .el-input .el-input__inner {
  padding: 0 18px;
  height: 34px;
}

.btnImg {
  display: block;
  width: 100%;
  height: 30px;
}
.btnImg img {
  width: 30px;
  height: 100%;
  float: right;
}
</style>
<style lang="less" scoped>
@import "~style/index.less";
.SpecialBox{
        &::first-letter {
            font-size: 72%;
        }
        line-height: 26px;
        color: #FFFFFF;
        font-weight: bold;
        font-size:15px;
        font-family: smallYuan;
      }
.counterBox{
  position: relative;
  .counter{
    position: absolute;
    top: -5px;
  }
}
#StoreBox{
  .selectedAllBox{
    padding-bottom:10px;
    margin-bottom: 6px;
    border-bottom: 1px dotted #ccc;
  }
.MembersBox{
  max-height:400px;
  overflow-y: scroll;
  float: left;
  width: 100%;
}

::-webkit-scrollbar  
{  
    // width:3px;  
     width:8px;  
}
  
/*定义滚动条轨道 内阴影+圆角*/  
::-webkit-scrollbar-track  
{  
    border-radius: 10px;  
    background-color: rgba(0,0,0,0.1); 
} 
  
/*定义滑块 内阴影+圆角*/  
::-webkit-scrollbar-thumb  
{  
    border-radius: 10px;  
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);  
    background-color: rgba(0,0,0,0.1);
} 
.LineBox{
    padding-bottom:10px;
    margin-bottom: 6px;
    border-bottom: 1px dotted #ccc;
    label{
      line-height: 20px;
      padding-top:5px;
    }
  }
  .el-dialog{
    background: url('../../assets/alertBg.png') no-repeat,#fff;
    background-size:100% auto;
    padding-top:70px;
    border-radius: 10px;
    .el-dialog__header{
        padding: 10px 20px 0px;
    }
    .el-dialog__body{
      padding:10px 20px;
    }
    .el-dialog__close{
      display: none;
    }
    .dialog-footer{
       .el-button{
            margin: 0 auto!important;
          display: block!important;
          background: #ff4800!important;
          border-radius: 30px!important;
          border: 1px solid #ff4800!important;
          padding: 10px 50px;
        }
      }
  }
  
}


.tagBox{
  line-height: 30px;
  height: 30px;
 .tag{
  //  width:30px;
  padding:0 3px;
   text-align: center;
        
          // padding: 0 5px;
          height:16px;
          line-height:16px;
          margin-top: 2px;
          font-size:12px;
    border-style: solid;
    box-sizing: border-box;
    white-space: nowrap;
    display: inline-block;
        margin-right:3px;
          &.radiusBox{
            border-radius:50%;
              width: 37px;
          }
          &.titColor{
            color:#fff
          }
          &.bgRed{
             background-color: #DD1F1F;
              border-color: #DD1F1F;
          }
         &.bgBlue{
              background-color: #409eff;
              border-color: #409eff;
          }
          &.bgGreen{
            background-color:  #52C41A;
              border-color:  #52C41A;
          }
          &.bgOrange{
             background-color:#FF7A45;
               border-color: #FF7A45;
          }
    }
}

.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translateY(50px);
}
/* v-enter-active 【入场动画的动作】 */
/* v-leave-active 【离场动画的动作】 */
.v-enter-active,
.v-leave-active {
  /* 设置动画过渡：过度所有属性，时间0.8秒，过渡方式 */
  transition: all 0.8s ease;
}

.item {
  width: 228px;
  background: #fff;
  float: left;
  margin-top: 15px;
  margin-right: 15px;
  padding-bottom: 10px;
  position: relative;
  cursor: pointer;
   
  &:hover{
   box-shadow: 10px 6px 6px -4px #dedada;
		border-radius:7px;
  }
  .ProductLabel{
          background-color: #ff3333;
          border-color: #ff3333;
          color: #fff;
          // border-radius: 5px;
          height: 25px;
          padding: 0 5px;
          line-height: 24px;
          border-width: 1px;
          font-size: 14px;
          border-style: solid;
          box-sizing: border-box;
          display: inline;
          position: absolute;
          max-width: 135px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          top: 0;
          left: 0px;
          z-index: 3;
    }
  .imgWrap {
    // padding-top: 15px;
    overflow: hidden;
    position: relative;
    height: 240px;
    .TopRightBox{
      position: absolute;
      top: 0;
      right: 0;
      z-index: 4;
      width: 38%;
      line-height:40px;
      text-align: center;
      color: #FFFFFF;
      font-weight: bold;
      font-size:15px;
      &::first-letter {
          font-size:126%;
          color: #FFEEA4;
      }
      font-family: smallYuan;
    }
    .bottomLeftBox{
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 4;
      width: 35%;
      span:nth-child(1){
        text-align: center;
        line-height: 20px;
        background: linear-gradient(#FDEAC5, #F7D8A4 , #F7D8A4 );
        display: block;
        height: 20px;
        width:70%;
        color: #C30707 ;
        padding:0px 5px;
        margin:0 auto;
        border-radius: 10px;
        font-size: 13px;
        font-weight: bold;
      }
      .SpecialBox{
        &::first-letter {
            font-size: 72%;
        }
        display: block;
        width: 100%;
        line-height: 26px;
        text-align: center;
        color: #FFFFFF;
        font-weight: bold;
        font-size:15px;
        font-family: smallYuan;
      }
    }
    .bottomRightBox{
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 4;
      width:56%;
      color: #FFFFFF;
        font-size:12px;
        line-height:32px;
    }
    .SpeProBg{
      display: block;
        width:100%;
        height:100%;
        z-index: 2;
        position:absolute;
        bottom:0px;
        right:0px;
    }
    .soldOut{
        display: block;
        width:80px;
        height:80px;
        z-index:3;
        position:absolute;
        bottom:0px;
        right:0px;
    }
    img {
      // display: inline-block;
      // width: 100%;
      // height: 150px;
      display: block;
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
  }

  .desBox{
    padding-left:8px;
    padding-right:8px;
  }
  .buyCart {
    // width: 186px !important;
    width: 100%;
    background: #fff;
    box-shadow: 0px 7px 36px -15px #000;
     border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

  }
  .shopName {
    padding-top:6px;
    height: 22px;
    color: #333;
    font-size:18px;
    line-height: 22px;
    font-weight: 600;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom:8px;
    // overflow:hidden;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp:2;
    // -webkit-box-orient: vertical;
  }
  .specification {
    height:20px;
    line-height:20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size:14px;
    margin-bottom:8px;
    color: #999;
      span{
        border: 1px solid #979797 ;
        color: #979797 ;
        padding: 1px;
        border-radius: 3px;
      }
    
  }
  .validity {
    line-height:20px;
     font-size:12px;
     color: #f36219;
     padding:0 5px;
     background: #f3621912;
     span{
        border: 1px solid #979797 ;
        color: #979797 ;
        padding: 1px;
        border-radius:3px;
      }
  }
  .hidePrice {
    color: #ff3333;
    line-height:26px;
    .price {
      color: #ff3333;
      font-size:18px;
      line-height:26px;
          font-weight: bold;
    }
    del{
      padding-left: 10px;
      font-size:12px;
        color: #333;
      text-decoration: line-through
    }
     .bottomBox{
       float: right;
       font-size: 12px;
       color: #ff3333;
    }
  }
}
</style>

<script>
const InputNumbers = () => import("components/common/InputNumbers.vue");
import CartBtn from "@/assets/cartBtn.png";
import CartBtns from "@/assets/cartBtns.png";
import defaultImage from "@/assets/defaultImage.png";
import { AddCart,getProLimitNum } from "api/product.js";
import { mapMutations, mapState } from "vuex";
import { GetMemberStore } from "api/member.js";
import Qs from 'qs'
export default {
  name: "CommodityZone",
  data() {
    return {
      selectedAll:false,
      picSuffix:localStorage.getItem("productPic"),
      currentIndex: 0,
      //价格框显示隐藏
      status: false,
      num:0,
         cartSrc: CartBtn,
      cartSrcs: CartBtns,
      // pics: 1,
      defaultImage:defaultImage,
      promotionMap:'',
      StoreShow:false,
      StoreList:[],
      checkedIds: [], // 初始选中的值
      StoreType:1,
      numPriceArr:[],
      isLimitNums:''
    };
  },
  props: {
    info: {
      type: Object,
    },
  },
  methods: {
    
    ...mapMutations({
      setCarGoodsNum: "setCarGoodsNum",
    }),
    changeIndex(index) {
      this.currentIndex = index;
    },
    show() {
     this.isLimitNums=Number(this.info.quantity)
     if(this.info.isLimit==1){
        localStorage.setItem('StoreType',1)
        // 获取商品限购剩余次数
        getProLimitNum(this.info.id).then(res=>{
          console.log(res,"限购次数")
          if (res.status === 400) {
            this.num = this.info.minOrderNum;
          }else{
            console.log(res.data.data[0].purchaseLimitationNum)
            this.isLimitNums=Number(res.data.data[0].purchaseLimitationNum)
          }
        })
      }else{
        localStorage.removeItem('StoreType')
      }
        
      this.num = this.info.minOrderNum;
      // if(!this.boolean) return;
       if(this.info.quantity<Number(this.info.minOrderNum)){
        this.status = false;
      }else{
         this.status = true;
      }
      if(localStorage.getItem('StoreType')==null){
          GetMemberStore().then((res) => {
                this.StoreList=res.data.data.filter((item) => {
                  return item.auditStatus==4 && item.disableStatus!=1
                })
              if(this.StoreList.length>1){
                localStorage.setItem('StoreType',2)
                this.StoreType=localStorage.getItem('StoreType')

              }else{
                localStorage.setItem('StoreType',1)
              }
          })
      }else{
          if(localStorage.getItem('StoreType')>1){
            this.StoreType=localStorage.getItem('StoreType')
          }else{
            this.StoreType=1
          }
      }
    },
    hide() {
      // if(!this.boolean) return;
      this.status = false;
    },
    handleChange(value) {
      this.num = value;
    },
    AddStoreFun(data){
      if (this.checkedIds.length < 1) {
        this.$message({
          message: "最少勾选一个门店",
          type: "warning",
        });
        return false;
      }
        this.StoreShow = false
        //  多个店铺，加购物
        // 获取选中值。将商品添加到选中店铺
          const addcartDate = [];
          this.StoreList.forEach((item) => {
            if(item.flag){
                    addcartDate.push({
                      isRecentExpiration:data.isRecentExpiration,
                      quantity: item.num,
                      productId:data.id==-1?data.productId:data.id,
                      goodNum:data.goodNum,
                      memberStoreId: item.id
                    });
            }
          });
         console.log(addcartDate)
            // 加入购物车接口
            AddCart(addcartDate).then((data) => {
              // this.$router.push({ name: "Cart", query: { id: id } });
              if (data.data.code == 200) {
                // 提示信息
                this.$notify({
                  title: "加入购物车",
                  message: "成功",
                  type: "success",
                  duration: 1000,
                });
                this.setCarGoodsNum(this.num);
              } else {
                this.$notify({
                  title: "提示信息",
                  message: data.data.msg,
                  type: "error",
                  duration: 1000,
                });
              }
            });

          
    },
    // 全选
    AllSelectedFun() {
     this.selectedAll=!this.selectedAll
      let checkedIds = [];
      this.StoreList.forEach((item) => {
        checkedIds.push(item.id);
        item.flag = this.selectedAll ? true : false;
      });
      this.checkedIds = this.selectedAll ? checkedIds : [];

    },
    handleCheckChange(item){
      item.flag=!item.flag
       var bStop = true;
       for (var i = 0; i < this.StoreList.length; i++) {
          if (!this.StoreList[i].flag) {
            bStop = false;
            break;
          }
        }
        this.selectedAll=bStop
        let checkedIds = [];
        this.StoreList.forEach((item) => {
          if (item.flag) {
            checkedIds.push(item.id);
          }
        });
        this.checkedIds = checkedIds;
      console.log(this.checkedIds)

    },
    // 加入当前店铺购物车
addCartOneFun(data){
     if(data.price==="***"){
          this.$message({
              message:'请点击顶部右侧【手机商城】，扫码二维码下载商城App，上传认证信息',
              type:"warning"
          })
          return false
      }
 // 如果一个店铺则直接加购物，多个店铺则跳出弹窗显示店铺

          GetMemberStore().then((res) => {
                      this.StoreShow=false
                      //  一个店铺，直接加购物
                      this.StoreList=res.data.data
                      let memberStoreId=Qs.parse(sessionStorage.getItem("memberData")).memberStoreId
                      const addcartDate = [];
                      addcartDate.push({
                        isRecentExpiration:data.isRecentExpiration,
                        quantity: this.num,
                        productId:data.id==-1?data.productId:data.id,
                        goodNum:data.goodNum,
                        memberStoreId:memberStoreId
                        // memberStoreId:this.StoreList[0].id
                      });
                      // 加入购物车接口
                      AddCart(addcartDate).then((data) => {
                        // this.$router.push({ name: "Cart", query: { id: id } });
                        if (data.data.code == 200) {
                          // 提示信息
                          this.$notify({
                            title: "加入购物车",
                            message: "成功",
                            type: "success",
                            duration: 1000,
                          });
                          this.setCarGoodsNum(this.num);
                        } else {
                          this.$notify({
                            title: "提示信息",
                            message: data.data.msg,
                            type: "error",
                            duration: 1000,
                          });
                        }
                      })


                  // if(res.data.data.length>1){
                  //       this.StoreShow=true
                  //       this.StoreList=res.data.data
                  //       this.StoreList.forEach((item) => {
                  //         item.flag = false;
                  //         item.num = this.info.minOrderNum
                  //       });
                  // }else{
                     
                  //   }
          })

},

// 多店铺购物车
addCartMoreFun(data){
 if(data.price==="***"){
          this.$message({
              message:'请点击顶部右侧【手机商城】，扫码二维码下载商城App，上传认证信息',
              type:"warning"
          })
          return false
      }
 // 如果一个店铺则直接加购物，多个店铺则跳出弹窗显示店铺
          GetMemberStore().then((res) => {
              this.StoreShow=true
              // this.StoreList=res.data.data
//  只返回正常店铺数据     item.auditStatus==4   正常    item.disableStatus==1  锁定
            this.StoreList=res.data.data.filter((item) => {
              return item.auditStatus==4 && item.disableStatus!=1
            })
              this.StoreList.forEach((item) => {
                item.flag = false;
                item.num = this.info.minOrderNum
              });
          })

},

    addCartFun(data) {
      // {"isRecentExpiration":0,"quantity":10,"productId":1}
      if(data.price==="***"){
          this.$message({
              message:'请点击顶部右侧【手机商城】，扫码二维码下载商城App，上传认证信息',
              type:"warning"
          })
          return false
      }

        // 如果一个店铺则直接加购物，多个店铺则跳出弹窗显示店铺
          GetMemberStore().then((res) => {

                  if(res.data.data.length>1){
                        this.StoreShow=true
                        this.StoreList=res.data.data
                        this.StoreList.forEach((item) => {
                          item.flag = false;
                          item.num = this.info.minOrderNum
                        });
                  }else{
                    this.StoreShow=false
                    //  一个店铺，直接加购物
                    this.StoreList=res.data.data
                    const addcartDate = [];
                    addcartDate.push({
                      isRecentExpiration:data.isRecentExpiration,
                      quantity: this.num,
                      productId:data.id==-1?data.productId:data.id,
                      goodNum:data.goodNum,
                      memberStoreId:this.StoreList[0].id
                    });
                    // 加入购物车接口
                    AddCart(addcartDate).then((data) => {
                      // this.$router.push({ name: "Cart", query: { id: id } });
                      if (data.data.code == 200) {
                        // 提示信息
                        this.$notify({
                          title: "加入购物车",
                          message: "成功",
                          type: "success",
                          duration: 1000,
                        });
                        this.setCarGoodsNum(this.num);
                      } else {
                        this.$notify({
                          title: "提示信息",
                          message: data.data.msg,
                          type: "error",
                          duration: 1000,
                        });
                      }
                    });


          }

          console.log('是否多店铺'+this.StoreShow)
      });

    },
    jumpDetail(info,isRecentExpiration) {
      // 123456789  热销商品列表跳转
      // alert(info.productId)
      // alert(info.id===-1)
      if(info.id===-1){
        this.$router.push({ name: "ProductDetail", query: { id: info.productId,isRecentExpiration:isRecentExpiration } });
      }else{
          this.$router.push({ name: "ProductDetail", query: { id: info.id,isRecentExpiration:isRecentExpiration } });
      }
    },
  },
  computed: {
    ...mapState({
      isqualification: (state) => state.isqualification,
    }),
  },
  watch: {
    "info": function(newValue) {
      if (newValue) {
        if(newValue.numPrice && newValue.numPrice!=''){
          this.numPriceArr=JSON.parse(newValue.numPrice)
        }
      }
    },
  }, 
  created() {
   
    
    if(this.info.numPrice && this.info.numPrice!=''){
        this.numPriceArr=JSON.parse(this.info.numPrice)
      }
      // if(this.info.promotionProductVOS==undefined){
      //    this.promotionMap=''
      // }else{
      //     if(this.info.promotionProductVOS.length >0 ){
      //         if(this.info.promotionProductVOS.length==1){
      //             this.promotionMap=this.info.promotionProductVOS[0].name
      //         }else{
      //           this.promotionMap='促销活动'
      //         }
      //     }else{
      //       this.promotionMap=''
      //     }
      // }
  },
  updated() {
  },
   components:{
        InputNumbers
    }
};
</script>
